import { intersection } from 'ramda'
import * as Yup from 'yup'
import { isValidLanguage as isValidLanguageImported } from '../locales/dictionary'
import { OrganizationWithBilling } from '../types'
import { OrganizationOnboardingRequest } from '../types/auth'

export const isValidLanguage = isValidLanguageImported

export const phoneRegEx = /^\+\d{5,20}$/
export const sanitizePhone = (phone?: string | null) =>
  phone ? phone.replace(/[\s()-]/g, '') : null

export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  !!phoneNumber?.match(phoneRegEx)

export const adminUserSchema = Yup.object({
  email: Yup.string().lowercase().email().required().label('Email'),
  name: Yup.string().required().label('Name'),
  phone: Yup.string()
    .required()
    .transform(sanitizePhone)
    .matches(phoneRegEx, 'phoneFormat')
    .label('Phone'),
})

export const emailSchema = Yup.object({
  email: Yup.string().required().lowercase().email().label('User email'),
})

export const validDomainRegex =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/

// Builder would be cleaner but clashes with injection of atLeastOneLanguage to Yup.ObjectSchema in Locales
interface DomainsCheckingStrategy {
  areDomainsAvailable?: (domains: string[]) => boolean // set&unset appropriate resolver in frontend/backend
}
export const domainsCheckingStrategy: DomainsCheckingStrategy = {}

const organizationAddressingSchema = {
  name: Yup.string().required().label('Organization name'),
  identifier: Yup.string()
    .required()
    .matches(/^([A-Z]+ ?)?[0-9-]+$/, 'businessIdOnly')
    .label('Organization Business id / VAT id'),
  addressStreet: Yup.string().required().label('Street address'),
  addressPostalCode: Yup.string().required().label('Postal code'),
  addressCity: Yup.string().required().label('City'),
  addressCountry: Yup.string().label('Country'),
}

const organizationPaymentSchema = {
  invoiceType: Yup.string().required(),
  pdfInvoiceReceiver: Yup.string()
    .label('Pdf Invoice receiver')
    .when('invoiceType', {
      is: 'pdf',
      then: Yup.string().required().lowercase().email(),
    }),
  invoiceContact: Yup.string().label('Invoice contact').when('invoiceType', {
    is: 'electronic',
    then: Yup.string().required().lowercase().email(),
  }),
  eInvoiceAddress: Yup.string().label('Invoice address').when('invoiceType', {
    is: 'electronic',
    then: Yup.string().required(),
  }),
  eInvoiceOperator: Yup.string().label('Invoide operator').when('invoiceType', {
    is: 'electronic',
    then: Yup.string().required(),
  }),
}

const organizationWithPaymentSchema = {
  ...organizationAddressingSchema,
  ...organizationPaymentSchema,
}

const organizationDomainsSchema = {
  useOrganizationDomains: Yup.boolean(),
  domains: Yup.array()
    .of(Yup.string())
    .label('Organization access')
    .when('useOrganizationDomains', {
      is: true,
      then: Yup.array()
        .of(
          Yup.string().strict(true).required().matches(validDomainRegex, {
            message: 'domainSyntax',
          }),
        )
        .strict(true)
        .required()
        .test(
          'Domains availability',
          'domainAvailability',
          (domain: string[] | undefined | null) =>
            !Array.isArray(domain) ||
            !domainsCheckingStrategy.areDomainsAvailable ||
            domainsCheckingStrategy.areDomainsAvailable(domain ?? []),
        ),
    }),
}

const organizationAddressingDedicatedSchema = Yup.object(
  organizationAddressingSchema,
)
const organizationAddressingCorporateSchema = Yup.object({
  ...organizationAddressingSchema,
  ...organizationDomainsSchema,
})

export const organizationAddressingOnboardingRequestSchema = Yup.object({
  adminUser: adminUserSchema,
  users: Yup.array().when('corporateSubscription', {
    is: false,
    then: Yup.array().of(emailSchema).label('Users'),
  }),
  termsAccepted: Yup.boolean().oneOf([true]),
  corporateSubscription: Yup.boolean(),
  organization: Yup.object().when('corporateSubscription', {
    is: true,
    then: organizationAddressingCorporateSchema,
    otherwise: organizationAddressingDedicatedSchema,
  }),
  organizationCountry: Yup.object().nullable().required().label('Country'),
})

const organizationWithPaymentDedicatedSchema = Yup.object(
  organizationWithPaymentSchema,
)
const organizationWithPaymentCorporateSchema = Yup.object({
  ...organizationWithPaymentSchema,
  ...organizationDomainsSchema,
})

export const organizationWithPaymentOnboardingRequestSchema = Yup.object({
  adminUser: adminUserSchema,
  users: Yup.array().when('corporateSubscription', {
    is: false,
    then: Yup.array().of(emailSchema).label('Users'),
  }),
  termsAccepted: Yup.boolean().oneOf([true]),
  corporateSubscription: Yup.boolean(),
  organization: Yup.object().when('corporateSubscription', {
    is: true,
    then: organizationWithPaymentCorporateSchema,
    otherwise: organizationWithPaymentDedicatedSchema,
  }),
  inviteCode: Yup.string(),
})

export const onboardingWithPaymentRequestSchemaNoOrg = Yup.object({
  adminUser: adminUserSchema,
  users: Yup.array().when('corporateSubscription', {
    is: false,
    then: Yup.array().of(emailSchema).label('Users'),
  }),
  termsAccepted: Yup.boolean().oneOf([true]),
  inviteCode: Yup.string(),
})

export const organizationFromOnboardingRequest = (
  request: OrganizationOnboardingRequest,
): OrganizationWithBilling => {
  const { organization, adminUser } = request
  const invoicingOverride =
    organization.invoiceType === 'pdf'
      ? {
          pdfInvoiceReceiver:
            organization.pdfInvoiceReceiver ?? adminUser.email,
          eInvoiceAddress: undefined,
          eInvoiceOperator: undefined,
          invoiceContact: undefined,
        }
      : {
          pdfInvoiceReceiver: undefined,
          invoiceContact: organization.invoiceContact ?? adminUser.email,
        }

  return {
    ...organization,
    ...invoicingOverride,
  }
}

const stringSchema = (label: string, mandatory?: boolean) =>
  mandatory
    ? Yup.string().nullable().required().label(label)
    : Yup.string().nullable().label(label)

const stringArraySchema = (label: string, mandatory?: boolean) =>
  mandatory
    ? Yup.array().of(Yup.string()).nullable().required().label(label)
    : Yup.array().of(Yup.string()).nullable().label(label)

export const visitorInfoSchema = (
  titlesMandatory: boolean,
  contentMandatory: boolean,
  checksMandatory: boolean,
) => {
  return Yup.object()
    .nullable()
    .shape({
      description: Yup.string().nullable().label('Description'),
      arrivalInfo: Yup.string().nullable().label('Arrival info'),
      title: stringSchema('Title', titlesMandatory),
      content: stringSchema('Content', contentMandatory),
      checks: stringArraySchema('Current checks', checksMandatory),
    })
    .nullable()
}

export const hasSome = <T>(haystack: T[], needle: T[] | T): boolean => {
  return (
    intersection(haystack, Array.isArray(needle) ? needle : [needle])?.length >
    0
  )
}

export const organizationBillingDetailsValidationSchema = Yup.object({
  invoiceType: Yup.string(),
  pdfInvoiceReceiver: Yup.string()
    .email()
    .when('invoiceType', {
      is: 'pdf',
      then: Yup.string().required(),
    })
    .label('PDF receiver'),
  eInvoiceOperator: Yup.string()
    .when('invoiceType', {
      is: 'electronic',
      then: Yup.string().required().min(1).max(50),
    })
    .label('e-invoicing operator'),
  eInvoiceAddress: Yup.string()
    .when('invoiceType', {
      is: 'electronic',
      then: Yup.string().required().min(1).max(50),
    })
    .label('e-invoicing address'),
  invoiceContact: Yup.string()
    .email()
    .when('invoiceType', {
      is: 'electronic',
      then: Yup.string().required(),
    })
    .label('Contact email address'),
  cardholderName: Yup.string()
    .when('invoiceType', {
      is: 'credit_card',
      then: Yup.string().required(),
    })
    .label('Cardholder name'),
  cardholderEmail: Yup.string()
    .email()
    .when('invoiceType', {
      is: 'credit_card',
      then: Yup.string().required(),
    })
    .label('Billing email'),
})
